<template>
  <div>
    <div class="border border-solid border-blue-200 pt-4">
      <datatable
        :ajax="true"
        :ajax-pagination="true"
        :url="requests.url"
        :columns="requests.columns"
        :query="requests.query"
        :on-click="click"
        :actions="requests.actions"
        dropdown="actions"
        sort="created_at"
        order="asc"
        ref="table"
      >
        <template #header>
          <search-form
            v-model="requests.query"
            class="mb-5"
            placeholder="Search for user's name, status, etc."
            @submit="reloadTable"
          />
        </template>
      </datatable>
    </div>

    <modals-user
      :selected-user="requests.selected"
      ref="userModal"
      @delete="userDeleted"
      @close="userModalClosed"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentFilter: null,
      requests: this.$options.resource([], {
        url: `${this.$baseurl}/admin/users/credit-limits/pending`,
        query: '',
        selected: null,
        columns: [
          {
            name: 'user_id',
            th: 'User ID',
          },
          {
            name: 'name',
            th: 'Name',
            render: (user) => `${user.name} ${user.last_name}`,
          },
          {
            name: 'email',
            th: 'Email Address',
          },
          {
            name: 'salary',
            th: 'Current Salary',
            sortable: false,
            render: (request) => this.$options.filters.currency(request.salary),
          },
          {
            name: 'desired_limit',
            th: 'Requested Limit',
            render: (request) =>
              this.$options.filters.currency(request.desired_limit),
          },

          {
            name: 'created_at',
            th: 'Created At',
            render: ({ created_at }) =>
              this.$moment(created_at).format('MMM Do YYYY'),
          },
        ],
        actions: [
          {
            text: 'Review Request',
            class:
              'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
            action: this.reviewRequest,
          },
          {
            text: 'Reject Request',
            class:
              'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
            action: this.rejectLimitRequest,
          },
          {
            text: 'Mark As Reviewed',
            class:
              'border-blue-500 text-white-500 rounded-sm px-4 py-2 hover:bg-gray-200',
            action: this.markAsReviewed,
          },
        ],
      }),
    };
  },
  methods: {
    click(request) {
      this.requests.selected = request;
      // this.$refs.userModal.open();
    },
    reloadTable() {
      this.$refs.table.loadAjaxData();
    },
    userDeleted() {
      this.reloadTable();
    },
    userModalClosed() {
      this.requests.selected = null;
    },
    async reviewRequest({ user_id }) {
      this.$router.push({
        name: 'staff-view',
        params: { staffId: user_id },
        query: { department: 'sales', tab: 'loanbot' },
      });
    },
    async markAsReviewed({ id }) {
      await this.sendRequest('admin.users.markIncreaseLimitAsReviewed', id, {
        success: async () => {
          this.$success({
            title: 'Operation Successful',
            body: 'Mark As Reviewed',
            button: 'Okay',
          });
          await this.$refs.table.loadAjaxData();
        },
        error: (error) => {
          console.log(error);
        },
      });
    },
    async rejectLimitRequest({ id }) {
      await this.sendRequest('admin.users.rejectIncreaseLimit', id, {
        success: async () => {
          this.$success({
            title: 'Operation Successful',
            body: 'Rejected Increase Limit',
            button: 'Okay',
          });
          await this.$refs.table.loadAjaxData();
        },
        error: (error) => {
          console.log(error);
        },
      });
    },
  },
};
</script>
